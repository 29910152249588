<template>
   <Modal>
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p v-if="isDescription" class="font-bold ml-4 my-5 text-center">{{ title }}</p>
          <p class="text-flame text-center my-5">
            {{ description }}
          </p>
          <div class="flex">
            <Button class="bg-dynamicBackBtn text-white" @click="proceedAction($event)">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="closeModal()"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
</template>

<script>
export default {
    name: "ProceedActionModal",
    components: {
        Modal: () => import("./Modal"),
        Card: () => import("./Card"),
        Button: () => import("./Button"),
    },
    props: {
        title: {
          type: String,
          default: "Are you sure you want to make this remittance?",
        },
        description: {
          type: String,
          default: "Note: The payment will be settled from your Virtual Nuban Account available balance.",
        },
        isDescription: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
      closeModal(){
        this.$emit("cancel");
      },
      proceedAction(event){
        this.$emit("proceed", event);
      }
    }
}
</script>
